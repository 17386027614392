<template>
  <div class="page">
    <div class="content-box section">
      <easy-card title="商户信息">
        <icon-button slot="extra" icon="return" @click.native="goBack"/>
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 注册账号</template>
            {{ detailList.registerId }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 商户名称</template>
            {{ detailList.merchantName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 身份证号码</template>
            {{ detailList.idCardNo }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"></template>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 身份证人像面</template>
            <PictureMerchant :attachId="detailList.idCardFront" width="240px"/>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 身份证国徽面</template>
            <PictureMerchant :attachId="detailList.idCardBack" width="240px"/>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 营业执照</template>
            <PictureMerchant
                :attachId="detailList.businessLicensePhoto"
                width="240px"
            />
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 店铺照片</template>
            <PictureMerchant
                :attachId="detailList.merchantPhoto"
                width="240px"
            />
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>
      <div style="text-align: center">
        <el-button type="primary" @click="merchantVerify(1)">通过</el-button>
        <el-button type="danger" @click="merchantVerify(2)">拒绝</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {audit, selectCommercialTenantParticulars} from "@/api/user";
import PictureMerchant from "@/components/PictureMerchant";

export default {
  name: "merchant_verify",
  components: {
    PictureMerchant,
  },
  data() {
    return {
      id: "",
      detailList: {},
    };
  },
  methods: {
    //返回商户管理列表页
    goBack() {
      this.$router.back();
    },
    //查询商户信息详情
    getDetail(id) {
      selectCommercialTenantParticulars(id).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.detailList = {
            registerId: res.returnObject.registerId,
            merchantName: res.returnObject.merchantName,
            idCardNo: res.returnObject.idCardNo,
            idCardFront: res.returnObject.idCardFront,
            idCardBack: res.returnObject.idCardBack,
            businessLicensePhoto: res.returnObject.businessLicensePhoto,
            merchantPhoto: res.returnObject.merchantPhoto,
          };
        }
      });
    },
    //审核商户信息
    merchantVerify(e) {
      const userAuditId = this.id;
      const verifyStatus = e;
      audit(userAuditId, verifyStatus).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.goBack();
        }
      });
    },
  },
  mounted() {
    const id = this.$route.query.auditId;
    this.id = id;
    this.getDetail(id);
  },
};
</script>

<style lang="less" scoped>
</style>
